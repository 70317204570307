import Image from '../../../images/us.jpg'

export default function Example() {
  return (
    <div className="relative bg-white" id="whoAreWe">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
              src={Image}
              alt=""
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-indigo-900 sm:text-4xl" data-aos="fade-down">Qui sommes-nous ?</h1>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              MP Wealth Corp Group Consulting Ltd est une société internationale basée à Londres qui fournit des conseils aux entreprises et une assistance pour des projets mondiaux.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              MP Wealth Corp exerce également dans le service aérien, aujourd'hui en activité
              dans de nombreux pays tels que (Luxembourg, Suisse, Allemagne, Angleterre, Belgique et France).
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              Elle a été fondée par Arnold-Valentin Kashama qui en est
              également le gérant avec le Co gérant Benoit Thimister.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              Arnold-Valentin Kashama est un spécialiste en gestion et dans l'industrie du football. 
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-700" data-aos="fade-right">
              MP Wealth Corp Group Consulting Ltd aide les entreprises privées et les entités gouvernementales à tous les niveaux dans les pays en développement à atteindre leurs buts et objectifs.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
